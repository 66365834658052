import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import store from "./store";
import Notifications from "@kyvg/vue3-notification";
import vue3GoogleLogin from "vue3-google-login";
import { GOOGLE_CLIENT_ID } from "@/constants";
// Import without options
const MakeInvestment = defineAsyncComponent(() =>
  import("@/views/app/MakeAnInvestment/MakeAnInvestmentView.vue"),
);
const MakeInvestmentOnboarding = defineAsyncComponent(() =>
  import("@/views/app/MakeAnInvestment/MakeAnInvestmentOnboardingView.vue"),
);
const FinancialFreedomOnboardingView = defineAsyncComponent(() =>
  import("@/views/onboarding/FinancialFreedomOnboardingView.vue"),
);
const SignUp = defineAsyncComponent(() =>
  import("@/views/auth/SignUpView.vue"),
);
const GoogleLoad = defineAsyncComponent(() =>
  import("@/views/auth/GoogleLoadView.vue"),
);
const LoginView = defineAsyncComponent(() =>
  import("@/views/auth/LoginView.vue"),
);
const RouteView = defineAsyncComponent(() =>
  import("@/views/auth/RouteView.vue"),
);
const VerifyEmailView = defineAsyncComponent(() =>
  import("@/views/auth/VerifyEmailView.vue"),
);
const ForgotPasswordView = defineAsyncComponent(() =>
  import("@/views/auth/ForgotPasswordView.vue"),
);
const CheckEmailView = defineAsyncComponent(() =>
  import("@/views/auth/CheckEmailView.vue"),
);
const AccountVerifiedView = defineAsyncComponent(() =>
  import("@/views/auth/AccountVerifiedView.vue"),
);
const AccountVerifiedPhoneNumberView = defineAsyncComponent(() =>
  import("@/views/auth/AccountVerifiedPhoneNumberView.vue"),
);
const ResetPasswordView = defineAsyncComponent(() =>
  import("@/views/auth/ResetPasswordView.vue"),
);
const FinancialFreedomCalculatorView = defineAsyncComponent(() =>
  import("@/views/onboarding/FinancialFreedomCalculatorView.vue"),
);
const LoadFinancialFreedomResultView = defineAsyncComponent(() =>
  import("@/views/onboarding/LoadFinancialFreedomResultView.vue"),
);
const FinancialFreedomResultView = defineAsyncComponent(() =>
  import("@/views/onboarding/FinancialFreedomResultView.vue"),
);
const FinancialFreedomProgressResultView = defineAsyncComponent(() =>
  import("@/views/onboarding/FinancialFreedomProgressResultView.vue"),
);
const ManageYourGoalsView = defineAsyncComponent(() =>
  import("@/views/app/ManageYourGoalsView.vue"),
);
const LoadingView = defineAsyncComponent(() =>
  import("@/views/app/LoadingView.vue"),
);
const DashboardView = defineAsyncComponent(() =>
  import("@/views/app/DashboardView.vue"),
);
const GoalView = defineAsyncComponent(() => import("@/views/app/GoalView.vue"));
const AccountSettingsView = defineAsyncComponent(() =>
  import("@/views/app/AccountSettingsView.vue"),
);
const KycInformationView = defineAsyncComponent(() =>
  import("@/views/app/KycInformationView.vue"),
);
const SubscriptionSettingsView = defineAsyncComponent(() =>
  import("@/views/app/SubscriptionSettingsView.vue"),
);
const WithdrawView = defineAsyncComponent(() =>
  import("@/views/app/WithdrawView.vue"),
);
const CompoundInterestCalculatorView = defineAsyncComponent(() =>
  import("@/views/calculators/CompoundInterestCalculatorView.vue"),
);
const AddEmergencyFundView = defineAsyncComponent(() =>
  import("@/views/app/AddEmergencyFund/AddEmergencyFundView.vue"),
);
const AddEmergencyFundOnboardingView = defineAsyncComponent(() =>
  import("@/views/app/AddEmergencyFund/AddEmergencyFundOnboardingView.vue"),
);
const AddOtherGoalView = defineAsyncComponent(() =>
  import("@/views/app/AddOtherGoals/AddOtherGoalView.vue"),
);
const AddOtherGoalOnboardingView = defineAsyncComponent(() =>
  import("@/views/app/AddOtherGoals/AddOtherGoalOnboardingView.vue"),
);
const YourPlanView = defineAsyncComponent(() =>
  import("@/views/app/YourPlan/YourPlanView.vue"),
);
const YourPlanOnboardingView = defineAsyncComponent(() =>
  import("@/views/app/YourPlan/YourPlanOnboardingView.vue"),
);
const AddNewGoalsView = defineAsyncComponent(() =>
  import("@/views/app/AddNewGoals/AddNewGoalsView.vue"),
);
const AddNewGoalsOnboardingView = defineAsyncComponent(() =>
  import("@/views/app/AddNewGoals/AddNewGoalsOnboardingView.vue"),
);
const ContactUsView = defineAsyncComponent(() =>
  import("@/views/app/ContactUsView.vue"),
);
// const InvestmentFlowView = defineAsyncComponent(() =>
//   import("@/views/app/MakeAnInvestment/InvestmentFlowView.vue"),
// );
const StatementView = defineAsyncComponent(() =>
  import("@/views/app/StatementView.vue"),
);const CoinAnalysisView = defineAsyncComponent(() =>
  import("@/views/app/CoinAnalysisView.vue"),
);

const RenewalBanner = defineAsyncComponent(() =>
  import('@/components/SubscriptionRenewalComponent.vue'),
);

const LoginWithGoogleComponent = defineAsyncComponent(() =>
  import("@/components/LoginWithGoogleComponent.vue"),
);
const FinancialBenefitSection = defineAsyncComponent(() =>
  import("@/components/FinancialBenefitsSection.vue"),
);
// const SampleView = defineAsyncComponent(() => import("@views/SampleView.vue"));

const app = createApp(App);

app.use(Notifications);
app.use(store);
app.use(vue3GoogleLogin, { clientId: GOOGLE_CLIENT_ID });
app.component("investment-page", MakeInvestment);
app.component("investment-page-onboarding", MakeInvestmentOnboarding);
app.component("financial-freedom-onboarding", FinancialFreedomOnboardingView);
app.component("sign-up", SignUp);
app.component("google-load", GoogleLoad);
app.component("log-in", LoginView);
app.component("route-page", RouteView);
app.component("verify-email", VerifyEmailView);
app.component("forgot-password", ForgotPasswordView);
app.component("check-your-email", CheckEmailView);
app.component("account-verified", AccountVerifiedView);
app.component("account-verified-phone-number", AccountVerifiedPhoneNumberView);
app.component("reset-password", ResetPasswordView);
app.component("financial-freedom-calculator", FinancialFreedomCalculatorView);
app.component("load-financial-freedom-result", LoadFinancialFreedomResultView);
app.component("financial-freedom-result", FinancialFreedomResultView);
app.component(
  "financial-freedom-progress-results",
  FinancialFreedomProgressResultView,
);
app.component("manage-your-goals", ManageYourGoalsView);
app.component("app-loading", LoadingView);
app.component("app-dashboard", DashboardView);
app.component("app-goal", GoalView);
app.component("account-settings", AccountSettingsView);
app.component("kyc-information", KycInformationView);
app.component("subscription-settings", SubscriptionSettingsView);
app.component("app-withdraw", WithdrawView);
app.component("compound-interest-calculator", CompoundInterestCalculatorView);
app.component("add-emergency-fund", AddEmergencyFundView);
app.component("add-emergency-fund-onboarding", AddEmergencyFundOnboardingView);
app.component("add-other-goal", AddOtherGoalView);
app.component("add-other-goal-onboarding", AddOtherGoalOnboardingView);
app.component("your-plan", YourPlanView);
app.component("your-plan-onboarding", YourPlanOnboardingView);
app.component("add-a-goal", AddNewGoalsView);
app.component("add-a-goal-onboarding", AddNewGoalsOnboardingView);
app.component("contact-us", ContactUsView);
app.component("statement-view", StatementView);
app.component("coin-analysis-view", CoinAnalysisView);
app.component('RenewalBanner', RenewalBanner);
// app.component("investment-flow-view", InvestmentFlowView);
app.component("login-with-google-component", LoginWithGoogleComponent);
app.component("financial-benefit-section", FinancialBenefitSection);
//  the sample one
// app.component("sample-page", SampleView);

if (!document.querySelector(".root")) {
  app.mount("section.section-inner"); //Don't commit this line when you change it
} else {
  app.mount(".root"); //Don't commit this line when you change it
}
window.$store = store;
